body{
    margin: 0px;
    padding: 0px;
}
ul, ol{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
h1, h2, h3, h4, h5, h6{
    font-weight: normal;
    margin: 0px;
    padding: 0px;
}
button{
    outline: none;
    cursor: pointer;
}
select, textarea, input{
    outline: none;
}

.jtx{   text-align:justify;  
        text-justify:auto;}
.flex{display: flex;}
.aic{align-items: center;}
.jic{justify-content: center;}
.anim{transition:all 0.2s linear 0s;}
.fixed{position: fixed;}
.abs{position: absolute;}
.abc{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.rel{position: relative;}

.noul{text-decoration: none;}

.ibl{display: inline-block;}
.bl{display: block;}

.cfff{color: #fff;}
.c333{color: #333;}
.c777{color: #777;}
.c033{color: #333 !important;}

.s8{font-size: 8px;}
.s13{font-size: 13px;}
.s14{font-size: 14px;}
.s15{font-size: 15px;}
.s16{font-size: 16px;}
.s18{font-size: 18px;}
.s20{font-size: 20px;}
.s22{font-size: 22px;}
.s24{font-size: 24px;}
.s30{font-size: 30px;}
.s40{font-size: 40px;}
.s50{font-size: 50px;}

.fontn{
    font-weight: normal;
}
.fontb{
    font-weight: bold;
}

.loading{
    width: 200px;
    height: 6px;
    background: #e5e5e5;
    border-radius: 5px;
}
.loading .line{
    left: 0px;
    width: 25%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background: #2c7480;
    border-radius: 5px;
    animation-name: LoaderLines;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}
.loading .line1{
    left: 0;
    opacity: 0.25;
    width: 25%;
}
.loading .line2{
    left: 0;
    opacity: 0.25;
    width: 25%;
    animation-delay: .1s;
}
.loading .line3{
    left: 0;
    opacity: 0.25;
    width: 25%;
    animation-delay: .2s;
}
@keyframes LoaderLines{50%{left: 75%}}

.cover{
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.95);
}


/*ZUZ TOAST*/
.zuz-toast{
    background: #000;
    bottom: 20px;
    right: 20px;
    color: #fff;
    padding: 10px 20px;
    border-radius: 3px;
    z-index: 99999;
    transform: translateY(150px);
    opacity: 0;
}
.zuz-toast-visible{
    transform: translateY(0px);
    opacity: 1;
}
.zuz-toast-hidden{
    transform: translateY(-150px);
    opacity: 0;
}
.zuz-toast button{
    border: 0px;
    line-height: 1;
    padding: 4px 5px 4px 20px;
    color: #fff;
    background: rgba(0,0,0,0);
    margin-left: 20px;
    border-left: 1px #2d2d2d solid;
}
.zuz-toast button:hover{
    opacity: 0.5;
}