/* ---------------------------------------------------------------
 * Tema oscuro Tagify
/* ---------------------------------------------------------------*/
html[data-theme="dark"] .tagify {
    --tag-text-color: #000000c9;
    --placeholder-color: #ffffff40;
    --placeholder-color-focus: #ffffff69;
}

/* fix que hay que aplicar debido a definición problemática de color con !important en app.css (TODO: eliminar dicho !important de app.css). NOTA: esto viene del backoffice. En e-napsis puede que no sea necesario. */
html[data-theme="dark"] .tagify__tag>div span {
    color: var(--tag-text-color) !important;
}

html[data-theme="dark"] .tagify__tag--editable>div span {
    color: white !important;
}

html[data-theme="dark"] .tagify__tag__removeBtn:hover+div>span {
    color: white !important;
}

/* ---------------------------------------------------------------
 * Tagify (instancia)
/* ---------------------------------------------------------------*/
.tagify {
    flex-grow: 1;
}
