/* Variables de color si el tema es nulo o light */
html {
    --primary-color: #282c34;
    --primary-with-transparency: #282c34b3;
    --secondary-color: #ffffff;
    --second-background: #ffffff;
    --subtitulos: #d87218;
    --background-menu: rgb(255, 255, 255);
    --color-text-form-label: #282c34;
    --color-border-form-butons: #d87218;
    --color-text-form-butons: #ffffff;
    --color-background-form-butons: #d87218;
    --color-background-form-butons-hover: #dd8435;
    --color-text-form-butons-cancel: #d87218;
    --color-background-form-butons-cancel: #ffffff;
    --color-border-form-div-input: #cfcfcf;
    --color-text-form-p: red;
    --color-background-form-checkbox: #ffffff;
    --color-text-form-checkbox: #d87218;
    --color-background-form-checkbox2: #ffffff;
    --color-text-form-checkbox2: #d87218;
    --color-text-input: #282c34;
    --background-NavButton:  #d87218;
    --color-text-h2: #ffffff;
    --color-text-menu-avatar: #282c34;
    --color-background-menu-avatar: #ffffff;
    --color-text-menu-avatar-focus: #ffffff;
    --color-background-menu-avatar-focus: #d87218;
    --color-icon-cart: #ffffff;
    --color-icon-svg: #282c34;
    --color-text-title-section: #d87218;
    --color-border-body-div-div-div-header: #282c34;
    --color-background-form-select-app: #ffffff;
    --color-text-form-butons-delete: #ffffff;
    --color-background-form-butons-delete: #f50057;
    --color-border-orange-secondary: #f50057;
    --color-title-price: #5F5F5F;
    --color-mount-price: #282c34;
    --color-mount-discount: #f50057;
    --color-breadcrumbs-link: #282c34;
    --color-breadcrumbs-link-inneret: #969292;
    --color-background-modal-title: #d87218;
    --color-text-modal-title: #ffffff;
    --color-background-modal-content: #ffffff;
    --color-text-modal-content: #282c34;
    --color-background-modal-footer: #ffffff;
    --color-text-modal-footer: #282c34;
    --color-border-modal-footer: #cfcfcf;
    --color-background-select-menu: #d87218;
    --color-background-pagination-selected: #d87218;
    --color-background-pagination-text: #d87218;
    --color-text-pagination-active: #ffffff;
    --color-text-carrusel: #ffffff;
    --color-text-carrusel-inactive: #999898;
    --color-text-carrusel-no-select: #282c34;
    --color-background-carrusel-shadow: #0105d31a;
    --color-text-menu-responsive-focus: #ffffff;
    --color-background-menu-responsive-focus: #3f51b5;
    --color-border-barra-busqueda: #d87218;
    --color-text-barra-busqueda: #282c34;
    --color-background-barra-busqueda: #ffffff;
    --color-background-dropzone-inactive: #ffffff;
    --color-background-dropzone-active: #ffffff;
    --color-background-editor-text: #ffffff;
    --color-background-button-editor-text: #d87218;
    --color-background-button-editor-text-focus: #c99567;
    --color-border-button-editor-text: #282c34;
    --color-text-editor-text: #282c34;
    --color-ql-picker-item-ql-selected: #3f51b5;
    --color-ql-snow-ql-toolbar-button-ql-active-ql-stroke: #ffffff;
    --color-font-button-editor-text: #ffffff;
    --color-font-ql-editor-ql-blank-before: #6d6b6b;
    --color-background-ql-editor-ql-blank-before: #ffffff;
    --color-background-menu-editor-text: #d87218;
    --color-text-menu-editor-text: #282c34;
    --color-text-form-butons-acept-disable: #a0a0a0;
    --color-background-form-butons-acept-disable: #cecece;
    --color-MuiPaper-elevation8-color1: #00000023;
    --color-MuiPaper-elevation8-color2: #0000001f;
    --color-MuiPaper-elevation8-color3: #0000001f;
    --color-MuiPaper-root-background: #ffffff;
    --color-MuiPaper-root-text: #282c34;
    --color-MuiCard-root-background: #ffffff;
    --color-MuiCard-root-text: #282c34;
    --color-background-subtitle-accordion: #ffffff;
    --color-text-subtitle-accordion: #282c34;
    --color-p-accordion-text: rgb(93, 0, 129);
    --color-MuiTypography-colorTextSecondary:#797979;
    --color-p-span-text: #282c34;
    --color-background-p-span: #ffffff;
    --color-MuiButton-root-hover: #616ebb;
    --color-text-AccordionDetails-selected: #000000;
    --color-background-AccordionDetails-selected: #3f51b565;
    --color-text-form-butons-subscription: #ffffff;
    --color-background-form-butons-subscription: #d87218;
    --color-text-form-butons-subscription-content: #000000;
    --color-background-form-butons-subscription-content: #ffffff;
    --background-color-linearProgress-primary: #d87218;
    --background-color-linearProgress-primary-shadow: #ffbf86;
    --background-color-linearProgress-secondary: #ffb400;
    --background-color-linearProgress-secondary-shadow: #b1b1b1;
    --color-CircularProgress: #d87218;
    --color-subtitulo2-nombre-tutor: #000000;
    --color-titulo-preguntas-y-header-cards: #000000;
    --color-a-link: #551a8b;
    /** generic colors (non specific to a component) (light) (+dark when not overridden) */
    --color-text-over-color: #ffffff;
    --color-always-colored: #d87218;
    --color-colored-only-on-light: #d87218;
    --hover-effect: #d8721814;
    --background-tarjetas: #fff;
    --borde-tarjetas: #d87218;
}

/* Variables de color si el tema es dark */
html[data-theme="dark"] {
    --primary-color: rgb(255, 255, 255);
    --primary-with-transparency: #ffffffb3;
    --secondary-color: #282c34;
    --second-background: #282c34;
    --subtitulos: #ffffff;
    --background-menu: rgb(119, 119, 119);
    --color-text-form-label: #ffffff;
    --color-border-form-butons: #d87218;
    --color-text-form-butons: #ffffff;
    --color-background-form-butons: #d87218;
    --color-text-form-butons-cancel: #d87218;
    --color-background-form-butons-cancel: #ffffff;
    --color-border-form-div-input: rgb(119, 119, 119);
    --color-text-form-p: rgb(202, 115, 0);
    --color-background-form-checkbox: #282c34;
    --color-text-form-checkbox: rgb(119, 119, 119);
    --color-background-form-checkbox2: rgb(119, 119, 119);
    --color-text-form-checkbox2: #282c34;
    --color-text-input: #ffffff;
    --background-NavButton: #1a1a1a;
    --color-text-h2: #ffffff;
    --color-text-menu-avatar: #ffffff;
    --color-background-menu-avatar: #1a1a1a;
    --color-text-menu-avatar-focus: #ffffff;
    --color-background-menu-avatar-focus: #d87218;
    --color-icon-cart: #ffffff;
    --color-icon-svg: #ffffff;
    --color-text-title-section: #ffffff;
    --color-border-body-div-div-div-header: rgb(119, 119, 119);
    --color-background-form-select-app: #282c34;
    --color-text-form-butons-delete: #ffffff;
    --color-background-form-butons-delete: var(--color-text-form-p);
    --color-border-orange-secondary: var(--color-text-form-p);
    --color-title-price: rgb(172, 171, 171);
    --color-mount-price: #ffffff;
    --color-mount-discount: rgb(202, 115, 0);
    --color-breadcrumbs-link: #ffffff;
    --color-breadcrumbs-link-inneret: #979595;
    --color-background-modal-title: rgb(119, 119, 119);
    --color-text-modal-title: #ffffff;
    --color-background-modal-content: #282c34;
    --color-text-modal-content: #ffffff;
    --color-background-modal-footer: #282c34;
    --color-text-modal-footer: #ffffff;
    --color-border-modal-footer: #cfcfcf;
    --color-background-select-menu: rgb(119, 119, 119);
    --color-background-pagination-selected: rgb(119, 119, 119);
    --color-background-pagination-text: #ffffff;
    --color-text-pagination-active: #ffffff;
    --color-text-carrusel: #ffffff;
    --color-text-carrusel-inactive: #999898;
    --color-text-carrusel-no-select: #7c7c7c;
    --color-background-carrusel-shadow: #d4ceae21;
    --color-text-menu-responsive-focus: #ffffff;
    --color-background-menu-responsive-focus: #3f51b5;
    --color-border-barra-busqueda: rgb(209, 209, 209);
    --color-text-barra-busqueda: #ffffff;
    --color-background-barra-busqueda: rgb(119, 119, 119);
    --color-background-dropzone-inactive: #282c34;
    --color-background-dropzone-active: #ffffff;
    --color-background-editor-text: #282c34;
    --color-background-button-editor-text: rgb(161, 161, 161);
    --color-background-button-editor-text-focus: #c9c9c9;
    --color-border-button-editor-text: #5c5b5b;
    --color-text-editor-text: #ffffff;
    --color-ql-picker-item-ql-selected: #282c34;
    --color-ql-snow-ql-toolbar-button-ql-active-ql-stroke: rgb(121, 121, 121);
    --color-font-button-editor-text: #282c34;
    --color-font-ql-editor-ql-blank-before: #cecece;
    --color-background-ql-editor-ql-blank-before: #282c34;
    --color-background-menu-editor-text: rgb(161, 161, 161);
    --color-text-menu-editor-text: #282c34;
    --color-text-form-butons-acept-disable: #a0a0a0;
    --color-background-form-butons-acept-disable: #cecece;
    --color-MuiPaper-elevation8-color1:#ffffff17;
    --color-MuiPaper-elevation8-color2:#ffffff21;
    --color-MuiPaper-elevation8-color3:#ffffff09;
    --color-MuiPaper-root-background: rgb(119, 119, 119);
    --color-MuiPaper-root-text: #ffffff;
    --color-MuiCard-root-background: rgb(119, 119, 119);
    --color-MuiCard-root-text: #ffffff;
    --color-background-subtitle-accordion: rgb(119, 119, 119);
    --color-text-subtitle-accordion: #ffffff;
    --color-p-accordion-text: #282c34;
    --color-MuiTypography-colorTextSecondary:#dbd4d4;
    --color-p-span-text: #ffffff;
    --color-background-p-span: #282c34;
    --color-MuiButton-root-hover: #a0a0a0;
    --color-text-AccordionDetails-selected: #ffffff;
    --color-background-AccordionDetails-selected: #3f51b565;
    --color-text-form-butons-subscription: #ffffff;
    --color-background-form-butons-subscription: #1a1a1a;
    --color-text-form-butons-subscription-content: #ffffff;
    --color-background-form-butons-subscription-content: #5f5f5f;
    --background-color-linearProgress-primary: #d87218;
    --background-color-linearProgress-primary-shadow: #ffbf86;
    --background-color-linearProgress-secondary: #ffb400;
    --background-color-linearProgress-secondary-shadow: #b1b1b1;
    --color-CircularProgress: #d87218;
    --color-subtitulo2-nombre-tutor: rgb(206 206 206);
    --color-titulo-preguntas-y-header-cards: #ffffff;
    --color-a-link: var(--primary-color);
    /** generic colors (non specific to a component) (dark) */
    --color-colored-only-on-light: var(--primary-color);
    --hover-effect: #d8721814;
    --background-tarjetas: #282c34;
    --borde-tarjetas: #fff;
}

.App {
    padding: 60px 0px 150px 0px;
    width: 100%;
    min-width: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.MenuBar{
    padding: 20px 0px 0px 0px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.espacio-addclass{
    padding-right: 15px;
}

.TitleCart{
    padding: 45px 0px 0px 30px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.MuiContainer-maxWidthXl {
    max-width: 1920px;
    padding: 0 !important;
}

.MuiTypography-colorTextPrimary {
    color: var(--color-text-form-label) !important;
}

.MuiTouchRipple-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: inherit !important;
    border-radius: inherit;
    pointer-events: none;
}

.MuiGrid-spacing-xs-1 {
    width: calc(100% + 8px);
    margin: 0px !important;
}

.MuiCardContent-root.logincard{
    padding: 56px;
    /*border: orange 1px solid;*/
}

.MuiCardContent-root:last-child.logincard-bottom {
    padding-bottom: 56px;
}

.MuiCardContent-root.registercard{
    padding: 56px;
}

.MuiCardContent-root:last-child.registercard-bottom {
    padding-bottom: 56px;
}

.Login_register{
    padding: 120px 0px 160px 0px;
    align-content:center;
    width: 100%;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.Login_register form{
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.Login_register form div{
    background-color: var(--second-background);
    color: var(--primary-color);
}

.Login_register form div div{
    color: var(--color-text-input);
}

.Login_register form div fieldset{
    border: 1px solid var(--color-border-form-div-input) !important;
}

.Login_register form div label {
    color: var(--color-text-form-label);
}

.App form{
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.App form div {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.App form div div{
    color: var(--color-text-input);
}

.App form div fieldset{
    border: 1px solid var(--color-border-form-div-input) !important;
}
.App form div label {
    color: var(--color-text-form-label);
}

.Login_register form button {
    color: var(--color-text-form-butons);
}

.modal_title{
    background-color: var(--color-background-modal-title) !important;
    color: var(--color-text-modal-title) !important;
}

.modal_content{
    background-color: var(--color-background-modal-content) !important;
    color: var(--color-text-modal-content) !important;
}
.modal_footer{
    background-color: var(--color-background-modal-footer) !important;
    color: var(--color-text-modal-footer) !important;
    border-top: 0.5px solid var(--color-border-modal-footer) !important;
}

.breadcrumbs_icon{
    padding: 4px;
    width: 20px;
    height: 20px;
}

.breadcrumbs_link{
    display: 'flex';
    background-color: var(--secondary-color) !important;
    color: var(--color-breadcrumbs-link) !important;

}

.breadcrumbs_link_inneret{
    display: 'flex';
    background-color: var(--secondary-color) !important;
    color: var(--color-breadcrumbs-link-inneret) !important;
    cursor: default;
}

.textDecorationLine {
    background-color: var(--secondary-color) !important;
    color: var(--color-border-orange-secondary) !important;
    text-decoration-line: line-through;
}

.border_orange {
    border: 1px solid var(--color-border-orange-secondary) !important;
}

.textDecorationLineNormal {
    background-color: var(--secondary-color) !important;
    color: var(--color-text-title-section) !important;
}

.border_bisque {
    border: 1px solid var(--color-text-title-section) !important;
}

.title_price {
    color: var(--color-title-price) !important;
}

.mount_price {
    color: var(--color-mount-price) !important;
}

.mount_price_discount {
    color: var(--color-mount-discount) !important;
}

form button, form button.MuiButton-containedPrimary, button.MuiButton-containedPrimary {
    background-color: var(--color-background-form-butons);
    color: var(--color-text-form-butons);
    border: 1px solid var(--color-border-form-butons);
}

form button.MuiButton-outlinedPrimary, button.MuiButton-outlinedPrimary {
    color: var(--color-border-form-butons);
    border: 1px solid var(--color-border-form-butons);
}

form button.MuiButton-outlinedPrimary:hover, button.MuiButton-outlinedPrimary:hover {
    border-color: 1px solid var(--color-border-form-butons);
    background-color: transparent;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.button_accept {
    background-color: var(--color-background-form-butons) !important;
    color: var(--color-text-form-butons) !important;
    border: 1px solid var(--color-border-form-butons) !important;
}

/* evitar necesidad de esto, permitir usar bootstrap en conjunto con MUI para utilizar clases como btn y btn-primary */
button.button_blue {
    background-color: #0d6efd;
    color: var(--color-text-form-butons);
    border: 1px solid #0d6efd;
}
button.button_blue:hover {
    background-color: #0b5ed7;
    border-color: #0a58ca;
}
button.button_blue[disabled] {
    background-color: #0b5ed7;
    color: var(--color-text-form-butons);
    border-color: #0a58ca;
    opacity: 0.5;
}

.button_accept:hover, form button.MuiButton-containedPrimary:hover, button.MuiButton-containedPrimary:hover {
    background-color: var(--color-background-form-butons-hover);
}

.button_accept_disable {
    background-color: var(--color-background-form-butons-acept-disable) !important;
    color: var(--color-text-form-butons-acept-disable) !important;
    border: 1px solid var(--color-background-form-butons-acept-disable) !important;
}

.button_cancel {
    background-color: var(--color-background-form-butons-cancel) !important;
    color: var(--color-text-form-butons-cancel) !important;
    border: 1px solid var(--color-background-form-butons-cancel) !important;
}

.button_delete {
    background-color: var(--color-background-form-butons-delete) !important;
    color: var(--color-text-form-butons-delete) !important;
    border: 1px solid var(--color-background-form-butons-delete) !important;
}

.button_accept_subscription {
    background-color: var(--color-background-form-butons-subscription) !important;
    color: var(--color-text-form-butons-subscription) !important;
}

.MuiCard-root-subscription {
    color: var(--color-MuiCard-root-text) !important;
    background-color: var(--color-background-form-butons-subscription-content) !important;
}

.MuiCardContent-root-subscription {
    color: var(--color-text-form-butons-subscription-content) !important;
    background-color: var(--color-background-form-butons-subscription-content) !important;
}

.MuiCardHeader-root-small {
    display: flex;
    padding: 0px;
}

.MuiTypography-h5-small {
    font-size: 0.9rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400 !important;
    line-height: 1.1 !important;
    letter-spacing: 0em;
}

.jMevsz-small {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 110% !important;
}

/*Botón flotante o Fab*/

.MuiFab-fixed {
    width: auto;
    height: 40px !important;
    padding: 0 26px;
    min-width: 48px;
    border-radius: 24px;
    position: fixed !important;
}

.btn-flotante {
    font-size: 16px; /* Cambiar el tamaño de la tipografia */
    text-transform: uppercase; /* Texto en mayusculas */
    font-weight: bold; /* Fuente en negrita o bold */
    color: #ffffff; /* Color del texto */
    border-radius: 5px; /* Borde del boton */
    letter-spacing: 2px; /* Espacio entre letras */
    background-color: #E91E63; /* Color de fondo */
    padding: 18px 60px; /* Relleno del boton */
    position: fixed;
    bottom: 25px;
    right: 40px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}
.btn-flotante:hover {
    background-color: #2c2fa5; /* Color de fondo al pasar el cursor */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
    .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    right: 20px;
    }
}

/*Fin Botón flotante o Fab*/

.checkbox {
    background-color: var(--color-background-form-checkbox) !important;
    color: var(--color-text-form-checkbox) !important;
}

.checkbox2 {
    background-color: var(--color-background-form-checkbox2) !important;
    color: var(--color-text-form-checkbox2) !important;
}


.InputText {
    background-color: var(--color-background-form-checkbox) !important;
    color: var(--color-text-input) !important;
}

.InputTextApp {
    background-color: var(--color-background-form-select-app) !important;
    color: var(--color-text-input) !important;
}

form a, form a[href] {
    color: var(--subtitulos);
}

form p {
    color: var(--color-text-form-p) !important;
}

body{
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

body div div div header{
    background-color: var(--background-NavButton);
    color: var(--primary-color);
    max-width: 100%;
}

body div div div header div{
    background-color: var(--background-NavButton);
    color: var(--primary-color);
}

h1{
    color: var(--color-text-h2);
}

h2{
    color: var(--color-text-h2);
}

.h3{
    color: var(--color-text-h2);
}

.ButtonNav{
    padding: 15px 15px;
    max-width: 100%;
    min-width: 18%;
    background-color: var(--background-NavButton);
    color: var(--primary-color);
}

div div ul{
    color: var(--color-text-menu-avatar);
}

.menu_items{
    background-color: red;
    color:green;
}

div div ul li div svg{
    color: var(--color-icon-svg);
}

div a span span svg path{
    background-color: var(--secondary-color);
    color: var(--color-icon-cart);
}

div p div ul li div div{
    background-color: var(--secondary-color);
    color: var(--color-text-title-section);
}

div p div ul li div div p{
    background-color: var(--secondary-color);
    color: var(--color-text-title-section);
}

div ul li p{
    background-color: var(--secondary-color);
    color: var(--color-text-title-section);
}

p span{
    color: var(--color-p-span-text);
    background-color: var(--color-background-p-span) !important;
}


div fieldset{
    border: 1px solid var(--color-border-form-div-input) !important;
}

.title_section{
    background-color: var(--secondary-color) !important;
    color: var(--color-text-title-section) !important;
}

.subtitle_title_accordion{
    background-color: transparent !important;
    color: var(--color-text-subtitle-accordion) !important;
}

.AccordionDetails-selected {
    color: var(--color-text-AccordionDetails-selected) !important;
    background-color: var(--color-background-AccordionDetails-selected) !important;
}


.color_p_accordion{
    color: var(--color-p-accordion-text) !important;
}

.marginleft{
    margin-left: 50px !important;
    margin-top: 0px !important;
}

.MuiAccordion-root {
    color: var(--color-MuiPaper-root-text) !important;
    background-color: var(--color-MuiPaper-root-background) !important;
}

.MuiAccordionDetails-root {
    display: inherit !important;
    padding: 8px 16px 16px;
}

div nav ul li button svg path{
    background-color: var(--color-background-pagination-butons);
    color: var(--color-background-pagination-butons);
}

.MuiPaginationItem-page {
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiPaginationItem-outlined {
    border: var(--color-background-pagination-text) !important;
}

.MuiPaginationItem-root {
    color: var(--color-background-pagination-text) !important;
    height: 32px;
    margin: 0 3px;
    padding: 0 6px;
    font-size: 0.875rem;
    min-width: 32px;
    box-sizing: border-box;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 16px;
    letter-spacing: 0.01071em;
}

.MuiPaginationItem-outlinedPrimary.Mui-selected {
    color: var(--color-text-pagination-active) !important;
    border: 1px solid var(--color-background-pagination-text) !important;
    background-color: var(--color-background-pagination-selected) !important;
}

/**
 * Carrusel
 * @todo simplificar definiciones y utilizar cascada para evitar duplicados.
 */
.rec-pagination .rec-dot {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: transparent;
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    box-shadow: 0 0 1px 2px var(--color-background-pagination-selected) !important;
    border-radius: 50%;
    outline: none;
}

.rec-pagination .rec-dot_active {
    background-color: var(--color-background-pagination-selected) !important;
    box-shadow: 0 0 1px 3px var(--color-background-pagination-selected) !important;
}

.rec-carousel > button.rec-arrow:disabled {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: var(--color-background-carrusel-shadow) !important;
    color: var(--color-text-carrusel-inactive) !important;
    box-shadow: 0 0 2px 0px var(--color-text-carrusel-inactive) !important;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
}

.rec-carousel > button.rec-arrow {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: var(--color-background-carrusel-shadow) !important;
    color: var(--color-text-carrusel-no-select) !important;
    box-shadow: 0 0 2px 0px var(--color-text-carrusel-no-select) !important;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
}

.rec-carousel > button.rec-arrow:hover:enabled:not(:disabled), .rec-carousel > button.rec-arrow:focus:enabled:not(:disabled) {
    color: var(--color-text-carrusel) !important;
    background-color: var(--color-background-pagination-selected) !important;
    box-shadow: 0 0 2px 0 var(--color-background-carrusel-shadow) !important;
}

.rec-carousel .rec-item-wrapper {
    padding: 10px !important;
}

.MuiMenuItem-root:focus {
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    /*min-height: 48px;*/ /* comentado para no alterar el alto de los elementos y mantener centrado vertical (mientras se resuelve el centrado para cualquier alto) */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
    color: var(--color-text-menu-avatar-focus) !important;
    background-color: var(--color-background-menu-avatar-focus) !important;
}

.botonflotando {
    position: absolute;
    will-change: transform;
    top: -70px;
    left: 30px;
    transform: translate3d(5px, 995px, 0px);
}

.RadioCenter {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 100px !important;
    margin-right: 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: unset !important;
}

.MuiBoxCargando {
    inset: 0px;
    display: flex;
    position: fixed !important;
    align-items: center !important;
    justify-content: center !important;
}

.MuiDialog-paperWidthSm-cargando {
    width: 90px !important;
}

.MuiAvatar-colorForced {
    color: #ffffff !important;
    background-color: #3f51b5 !important;
}

.arrow-avatar{
    color: #ffffff !important;
}

.MuiMenuItem-root{
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    min-height: 48px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
    color: var(--color-text-menu-avatar) !important;
    background-color: var(--color-background-menu-avatar) !important;
}

.menu_responsive{
    color: var(--color-text-menu-responsive-focus) !important;
    background-color: var(--color-background-menu-responsive-focus) !important;
}

.MuiCardMedia-root {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100% !important;
    max-height: 196px;
}

.MuiCardMedia-root_tutor {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100% !important;
}

.MuiCardActionArea-root {
    width: 100%;
    display: block;
    text-align: inherit;
    height: 290px !important; /*Altura ajustada*/
}

.MuiCardActionArea-root.cardareacategories {
    height: 270px !important; /*Altura ajustada*/
}

.photo {
    height: 200px;
    width: 350px;
  }

.dzu-dropzone{
    background-color: var(--color-background-dropzone-inactive) !important;
    overflow: revert !important;
    flex-direction: column-reverse !important;
}

.dzu-previewContainer{
    background-color: var(--color-background-dropzone-active) !important;
    padding: 40px 0% !important;
    display: flex;
    flex-direction: column !important;
}

.dzu-previewStatusContainer{
    background-color: var(--color-background-dropzone-active) !important;
}

.MuiAlert-message{
    color: white !important;
    background-color: rgba(255, 0, 0, 0) !important;
}

.MuiAlert-icon{
    color: white !important;
    background-color: rgba(255, 0, 0, 0) !important;
}

.MuiAlert-action{
    color: rgba(255, 0, 0, 0) !important;
    background-color: rgba(255, 0, 0, 0) !important;
}

.MuiAlert-root{
    color: rgba(92, 92, 92, 0) !important;
    background-color: rgba(255, 255, 255, 0) !important;
}

.MuiPaper-root.MuiAlert-filledError{
    color: rgba(20, 6, 6, 0) !important;
    background-color: red !important;
}

.MuiPaper-root.MuiAlert-filledSuccess{
    color: rgba(20, 6, 6, 0) !important;
    background-color: rgb(45, 167, 14) !important;
}

.MuiPaper-root.MuiAlert-filledWarning{
    color: rgba(20, 6, 6, 0) !important;
    background-color: rgb(255, 155, 23) !important;
}

.MuiPaper-root.MuiAlert-filledInfo{
    color: rgba(20, 6, 6, 0) !important;
    background-color: rgb(0, 113, 189) !important;
}

/* Alerts cuestionarios */

.MuiPaper-root.Alert-Error{
    color: rgba(20, 6, 6, 0) !important;
    background-color: red !important;
}

.MuiPaper-root.Alert-Success{
    color: rgba(20, 6, 6, 0) !important;
    background-color: rgb(45, 167, 14) !important;
}

.MuiPaper-root.Alert-Warning{
    color: rgba(20, 6, 6, 0) !important;
    background-color: rgb(255, 155, 23) !important;
}

.MuiPaper-root.Alert-Info{
    color: rgba(20, 6, 6, 0) !important;
    background-color: rgb(0, 113, 189) !important;
}

/*Titulo tarjetas (Card) de cursos tutor*/
.MuiTypography-h5{
    font-size: 1.2rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400 !important;
    line-height: 1.1 !important;
    letter-spacing: 0em;
}

.MuiCard-root {
    overflow: hidden;
    color: var(--color-MuiCard-root-text) !important;
    background-color: var(--color-background-form-butons-subscription-content) !important;
}

/*tarjetas (card) altura */
.MuiCard-root-altura {
    overflow: hidden;
    height: 360px;
    align-items: center !important;
}

/*Botones tarjetas (Card) de cursos tutor*/
.MuiCardActions-root {
    display: grid !important;
    padding: 8px;
    align-items: center !important;
}

/*Titulo tarjetas (Card) de mis cursos*/
.MuiTypography-body2 {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600 !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: var(--subtitulos) !important;
}

.MuiTypography-colorTextSecondary{
    color:var(--color-MuiTypography-colorTextSecondary) !important;
}

/*Diseño de modal (afecta borde de tarjetas)*/

.MuiPaper-rounded {
    border-radius: 0px !important; /* borde anterior redondeado card: 12px */
}

.MuiDialog-paperWidthSm {
    max-width: 600px !important;
    width: 600px !important;
}

.MuiDialogContent-dividers {
    padding: 40px 60px !important;
    border-top: 5px solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 16px 24px !important;
    align-items: center;
    justify-content: flex-end;
}

/*Sombra de tarjetas (Modificado por mi)*/
.MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px var(--color-MuiPaper-elevation8-color1), 0px 8px 10px 1px var(--color-MuiPaper-elevation8-color2), 0px 1px 14px 10px var(--color-MuiPaper-elevation8-color3) !important;
}

.MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: left !important;
}

.MuiButton-root-MisPrecioTarjetas {
    color: var(--primary-color) !important; /*rgba(0, 0, 0, 0.87);*/
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.MuiButton-root:hover {
    text-decoration: none !important;
}

.MuiButton-root::selection{
    text-decoration: none !important;
    background-color: transparent !important;

}

.CardEfect:hover{
    background-color: rgba(236, 234, 234, 0.274) !important;
}

.CardEfect{
    background-color: transparent !important;
}

.Pointer:hover{
    cursor: pointer !important;
}

.MuiLinearProgress-colorPrimary {
    background-color: var(--background-color-linearProgress-primary-shadow) !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: var(--background-color-linearProgress-primary) !important;
}

.MuiLinearProgress-colorSecondary {
    background-color: var(--background-color-linearProgress-secondary-shadow) !important;
}

.MuiLinearProgress-barColorSecondary {
    background-color: var(--background-color-linearProgress-secondary) !important;
}

div.CircularProgress {
    color: var(--color-CircularProgress);
}

/*Background de las tarjetas (Card). Nota: cambia color de los alert con important*/

.MuiPaper-root{
    color: var(--color-MuiPaper-root-text) !important;
    background-color: var(--color-MuiPaper-root-background) !important;
}

.MuiPaper-root_sider_responsive{
    color: black !important;
}

.MuiCard-root {
    color: var(--color-MuiCard-root-text) !important;
    background-color: var(--color-background-form-butons-subscription-content) !important;
}

.MuiTypography-body1 {
    font-size: 1rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 540 !important;
    line-height: 1 !important;
    letter-spacing: 0.00938em;
}

/**Cambio sprint 7 **/
.MuiListItemText-primary {
    color: var(--color-text-form-label) !important;
}
/**Cambio sprint 7 **/


.MuiTypography-root {
    margin: 0;
    margin-left: 0px !important;
}

.MuiTypography-root-small {
    margin: 0;
    margin-left: -10px !important;
}

.MuiTypography-subtitle2 {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.07;
    letter-spacing: 0.00714em;
    color: var(--color-subtitulo2-nombre-tutor) !important;
}

.MuiTypography-root.MuiCardHeader-title.MuiTypography-body2{
    color: var(--color-titulo-preguntas-y-header-cards) !important;
}

/*
Splash
*/
.splash{}


.splash img{
    height: 100px;
}

.splash-small img{
    height: 50px;
}

.splash-medium img{
    height: 70px;
}

li { display: list-item !important;}
body {
    list-style-type: circle;
}

/*
VIDEO PLAYER
*/
.player{
    width: 100%;
    height: 55%;
    background: #000;
}
.player video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.player .ctrls{
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 90px;
    background: rgba(62, 62, 62, 0.54);
    z-index: 9;
    padding: 0px 15px;
}
.player .ctrls .pp{
    border: 0px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: #2c7480;
    color: #fff;
}
.player .ctrls .timer{
    flex: 1;
    margin: 0px;
    text-align: center;
}
.player .ctrls .slider{
    flex: 3;
}
.player .ctrls .slider .prog{
    width: 100%;
    height: 10px;
    background: #ffffff;
    border-radius: 10px;
}
.player .ctrls .slider .bar{
    width: 40%;
    height: 10px;
    background: #2c7480;
    border-radius: 10px;
}
.player .ctrls .slider .bar .knob{
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 100%;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    box-shadow: 0px 0px 5px #000;
}
.player .ctrls .vol{
    border: 0px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: rgba(234, 82, 82, 0);
    color: #fff;
    margin: 0px 10px;
}
.player .ctrls .fs{
    border: 0px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: rgba(234, 82, 82, 0);
    color: #fff;
    margin: 0px 10px;
}


/*
SIDEBAR
*/
.sidebar {
    /*
    width: 75px;
    */

    max-width: 100%;

}

.sidebar .logo{
    padding: 15px 5px; /* padding: 20px 16px; */
}

.logo-pagina-principal{
    padding: 15px 5px; /* padding: 20px 16px; */
}

.sidebar .logo img{
    height: 50px;
    padding: 0px 10px;
}

.logo-pagina-principal img{
    height: 62px;
    padding: 0px 10px;
}

.sidebar .logocel{
    padding: 15px 5px; /* padding: 20px 16px; */
}

.logocel-pagina-principal{
    padding: 15px 5px;
}

.sidebar .logocel img{
    height: 30px;
    padding: 0px 10px;
}

.logocel img{
    height: 30px;
    padding: 0px 10px;
}

.logocel-pagina-principal img{
    height: 50px;
    padding: 0px 10px;
}

.logo-footer img{
    height: 40px;
    margin-top: 0px;
}

.logocel-footer img{
    height: 50px;
    padding: 0px 10px;
}

.sidebar .nav{
    padding: 0px 40px;
    max-width: 100%;
    min-width: 80%;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}

.sidebar .flotantes{
    padding: 15px 0px;
    max-width: 100%;
    min-width: 10%;
    margin-left: 0px;
    margin-right: 0px;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}

.sidebar .flotantes_group{
    padding: 15px 200px;
    max-width: 100%;
    min-width: 40%;
    margin-left: 195px;
    margin-right: 0px;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}

.sidebar .nav li{}

.sidebar .nav li a{
    padding: 10px 20px;
    margin-bottom: 6px;
}
.sidebar .nav li a .ico{
    margin-right: 10px;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}
.sidebar .nav li a .lbl{
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}

.sidebar .nav li a.active,

.sidebar .nav li a.on{
    /*border-left: 2px #2c7480 solid;*/
    border-bottom: 2px var(--color-background-form-butons) solid;
    color: #2c7480;
    line-height: 1;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}


.nav li a.on,
.nav li a.active h2 {
    /*border-left: 2px #2c7480 solid;*/
    border-bottom: 2px white/*var(--color-background-form-butons)*/ solid;
    color: #2c7480;
    line-height: 1.5;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);

}

.appbar-active.on{
    /*border-left: 2px #2c7480 solid;*/
    border-bottom: 2px white/*var(--color-background-form-butons)*/ solid;
    color: #2c7480;
    line-height: 1.5;
    background-color: var(--background-NavButton);
    color: var(--color-text-h2);
}

.CentrarLoading {
    padding: 20px 20px 20px 45% !important;
}

.CentrarLoadingSinModal{
    padding: 15% 20px 20px 49% !important;
}

.MuiPaper-elevation1 {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}
.course {
    width: 400px;
    height: 415px;
    padding: 10px;
}

.course-small {
    max-width: 310px;
    height: 415px;
    padding: 10px 10px 10px 10px;
}

.coursehome {
    width: 400px;
    height: 400px;
    padding: 10px;
}

.category {
    width: 290px;
    padding: 20px;
}

.categorySmall {
    width: 260px;
    padding: 0px;
}

.video {
    background: black;
    border: 5px solid black ;
    border-radius: 5px;
    text-align: center;
}

.sidebar .updated-course{
    padding: 0px 35px;
    margin: 20px 0px;
}
.sidebar .updated-course .ico{
    background: #2c7480;
    padding: 15px;
    border-radius: 22px;
    margin-right: 10px;
}
.sidebar .updated-course .lbl{}
.sidebar .updated-course .lbl .author{}

.course-stats, .sidebar .stats{
    padding: 70px 50px;
}
.course-stats .stats-box, .sidebar .stats .stats-box{
    flex-direction: column;
    width: 110px;
}
.course-stats .stats-box .ico, .sidebar .stats .stats-box .ico{
    margin-bottom: 15px;
}
.sidebar .stats .stats-box .val{}
.sidebar .stats .stats-box .lbl{}
.course-stats .ico-points, .sidebar .stats .stats-box .ico-points{color: #1aab53;}
.course-stats .ico-battery, .sidebar .stats .stats-box .ico-battery{color: #2c7480;}

.sidebar .me{
    padding: 20px 40px;
}
.sidebar .me .photo{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    margin-right: 10px;
    background: #eee;
}

.container-root {
    border: 1px solid var(--color-text-title-section);
    border-radius: 5px;
    padding: 5px;
    max-width: 100% !important;
  }

  .RichEditor-editor .public-DraftStyleDefault-pre pre {
    white-space: normal;
    border: 1px solid var(--color-text-title-section);
  }

.text-editor{
    white-space: normal;
    width: 100% !important;
    box-sizing: border-box !important;

}

.buttons{
    /*float: left;*/
    float: right;
    text-align: center;
}

.sidebar .me .photo img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.sidebar .me .lbl{}

.sidebar .me .link{}
.sidebar .me .link .ico{
    width: 50px;
    height: 50px;
    background: #2c7480;
    border-radius: 100%;
    margin-right: 10px;
}
.sidebar .me .link .ico:before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sidebar .me .link .lbl{}

.app-content{
    flex: 3;
    padding: 20px 0px 0px 0px !important;
    max-width: 100%;
    min-width: 280px !important;
    width: 1000px;
}

.app-content-small-new {
    flex: 3 1;
    padding: 20px 30px 0px 0px;
    max-width: 100%;
    width: 1000px;
}

.MuiToolbar-gutters {
    padding-left: 12px !important;
    padding-right: 2px !important;
}

.MuiGrid-spacing-xs-2 {
    width: calc(100%) !important;
    margin: -8px !important;
}


/*
HOME PAGE
*/

.round{
    width: 100% !important;
    padding: 0.7rem !important;
    border-radius: 25px !important; /*15px*/
    border: 3px  var(--color-border-barra-busqueda) solid !important; /*#2c7480 solid;*/
    background-color: var(--color-background-barra-busqueda) !important; /*#2c7480 solid;*/
    color: var(--color-text-barra-busqueda) !important; /*#2c7480 solid;*/
    font-size: 1.1em !important;
    margin-top: 10px;
    margin-left: 10px !important;
    top: 0;
    left: 0;
    z-index: 5;
}


.home-page{ /*Originalmente no tiene nada dentro, se coloco para probar tema oscuro*/
    }

.home-page .section{ padding: 100px 40px 20px 20px; }
.home-page .section .title{
    margin-bottom: 20px;
    padding: 0px 40px 20px 20px;
}
.home-page .section .tutors{

}
.home-page .section .tutors .tutor{
    border: 2px #2c7480 solid;
    background: #fff;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 50px;
    padding: 3px;
}
.home-page .section .tutors .tutor img{
    width: 100%;
    height: 100%;
    border-radius: 55px;
}
.home-page .section .tutors .tutor:hover{}

.section-b{
    margin-top: 50px;
}
.section .mycourses{
    margin: 10px;
}
.section .courses a{
    flex: 1;
    margin-right: 25px;
}
.section .courses .block{
    width: 315px;
    height: 375px;
    background: #e2e2e2;
    border-radius: 20px;
    background-size: cover !important;
}
.section .courses a:last-child{
    margin-right: auto;
}
.section .courses .block .user{
    padding: 15px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.75);
    border-top-left-radius: 20px;
    text-shadow: 1px 1px 1px #000;
}
.section .courses .block .user .pic{
    width: 40px;
    height: 40px;
    border: 2px #fff solid;
    border-radius: 50%;
    padding: 3px;
    margin-right: 10px;
}
.section .courses .block .user .pic img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.section .courses .block .user .meta{}
.section .courses .block .user .meta .name{}
.section .courses .block .user .meta .uname{}

.section .courses .block .dura{
    top: 55px;
    left: 20px;
    background: #2c7480;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.33);
    z-index: 1;
}
.section .courses .block .price{
    top: 95px;
    left: 200px;
    background: #2c7480;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.33);
    z-index: 1;
}

.section .courses .block .titleInstructor{
    top: 15px;
    left: 20px;
    background: #2c7480;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.33);
    z-index: 1;
}
.section .courses .block .priceInstructor{
    top: 55px;
    left: 20px;
    background: #2c7480;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.33);
    z-index: 1;
}

.section .courses .block .statusInstructor{
    top: 95px;
    left: 20px;
    background: gray;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.33);
    z-index: 1;
}

.center-button{
    text-align: center;
    bottom: 20px;
}

.section .courses .block  .course-title{
    bottom: 15px;
    left: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.75);
    padding: 15px 20px;
    border-radius: 20px;
    text-shadow: 1px 1px 1px #000;
}

.home-page .top-tutors{
    flex-wrap: wrap;
}
.home-page .section .user-block{
    margin: 0px 50px 30px 0px;
}
.home-page .section .user-block .pic{
    width: 40px;
    height: 40px;
    border: 2px #fff solid;
    border-radius: 50%;
    padding: 3px;
    margin-right: 10px;
}
.home-page .section .user-block .pic img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.home-page .section .user-block .meta{}
.home-page .section .user-block .meta .name{}
.home-page .section .user-block .meta .uname{}

/*
RIGHTBAR
*/
.rightbar{
    flex: 1;
    padding: 20px 0px;
}

.rightbar .search-box{
    margin: 10px 0px;
}
.rightbar .search-box .qry{
    flex: 1;
    border: 0px;
    padding: 15px;
    background: #f1f1f1;
    margin-right: 10px;
    border-radius: 5px;
}
.rightbar .search-box .qry:focus{
    background: #e0e0e0;
}
.rightbar .search-box .go{
    border: 0px;
    background: #2c7480;
    padding: 0px 20px;
    border-radius: 5px;
}
.rightbar .search-box .go:hover{
    background: #64a798;
}
.rightbar .courses{
    flex-direction: column;
}
.rightbar .courses .cols{
    flex-direction: row;
}
.rightbar .course-a .block{
    width: 100%;
    height: 250px;
    margin-bottom: 25px;
}
.rightbar .courses .cols .block{
    height: 290px;
}

.rightbar .special-offer{
    background: #e5fcfb;
    height: 185px;
    margin-top: 14px;
    border-radius: 20px;
    overflow: hidden;
}
.rightbar .special-offer .meta{
    padding: 30px;
    flex: 1;
    z-index: 2;
}
.rightbar .special-offer .meta .h1{color: #2c7480;}
.rightbar .special-offer .meta .h2{
    margin-top: 6px;
    color: #06a9a2;
}
.rightbar .special-offer .meta button{
    background: #fff;
    border: 0px;
    border-radius: 10px;
    padding: 14px 20px;
    margin-top: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.rightbar .special-offer .meta button:hover{
    background: #2c7480;
    color: white;
}
.rightbar .special-offer .vector{
    padding-top: 30px;
    width: 150px;
    z-index: 2;
}
.rightbar .special-offer .vector img{}
.rightbar .special-offer .ring{
    width: 100px;
    height: 100px;
    background: #90e6e3;
    border-radius: 100%;
    right: -90px;
    top: 50%;
    transform: translateY(-50%);
    border: 40px rgba(255, 255, 255, 0.45) solid;
}

/*
COURSE PREVIEW
*/
.course-page{}
.course-info{flex: 1; padding-top: 80px;}
.course-page .tutor{}
.course-page .tutor .pic{
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #eee;
    margin-right: 10px;
}
.course-page .tutor .pic img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.course-page .tutor .meta{}
.course-page .tutor .meta .name{}
.course-page .tutor .meta .uname{}
.course-page .course-meta{
    padding: 40px 0px;
}
.course-page .course-meta .title{
    font-weight: 600;
}
.course-page .course-meta .about{}

.course-videos{
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0px;
}
.course-videos a{
    margin: 10px 0px;
}
.course-videos a .id{
    background: #2c7480;
    width: 50px;
    height: 50px;
    border-radius: 14px;
    padding: 14px 20px;
    box-sizing: border-box;
    margin-right: 10px;
}
.course-videos a .meta{}
.course-videos a .meta .lbl{}
.course-videos a .meta .dur{}
.course-videos a:hover{}

.course-preview{
    flex: 1;
    margin-left: 20px;
    padding-top: 100px;
}

.img{

    width: 50%;
}

.extras-block{
    margin-left: 30px;
}


.chat{
    flex: 1;
    margin-right: 50px;
}
.chat .messages{
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    padding: 30px 0px;
}
.chat .messages .bubble{
    align-self: flex-start;
    margin: 7px 0px;
    width: 100%;
}
.chat .messages .bubble .txt{
    background: #d6d6d6;
    line-height: 1;
    padding: 14px;
    border-radius: 20px 20px 20px 3px;
}
.chat .messages .bubble-mine{
    text-align: right;
}
.chat .messages .bubble-mine .txt{
    border-radius: 20px 20px 3px 20px !important;
    background: #272727;
    color: #fff;
}

.now-watching{
    flex: 1;
}
.now-watching .tooltip{
    background: #2c7480;
    top: 50px;
    right: 0px;
    padding: 8px 15px;
    border-radius: 10px;
}
.now-watching .you-list{margin-top :25px;}
.now-watching .you{
    margin-bottom: 30px;
}
.now-watching .you .pic{
    width: 40px;
    height: 40px;
    margin-right: 12px;
    background: #ddd;
    border-radius: 100%;
}
.now-watching .you .pic img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.now-watching .you .meta{}
.now-watching .you .meta .name{}
.now-watching .you .meta .uname{}


/*
OAUTH VIEW
*/
.oauth-view{
    width: 301px;
    margin: 100px auto;
}
.oauth-view-verify{width: 420px;}
.oauth-view .otitle{}
.oauth-view .oline{
    margin-bottom: 30px;
    margin-top: 10px;
}
.oauth-view .iput{
    width: 100%;
    border: 1px #b1b1b1 solid;
    padding: 20px;
    border-radius: 3px;
    margin: 10px 0px;
    box-sizing: border-box;
}
.oauth-view .button{
    width: 100%;
    border: 1px #81d4c2 solid;
    padding: 20px;
    border-radius: 3px;
    margin: 10px 0px;
    box-sizing: border-box;
    background: #2c7480;
}
.oauth-view .button:hover{
    background: #81d4c2;
}
.oauth-view .__phonesigner__{
    margin: 10px 0px 20px 0px;
}

/*
ADDCLASS
*/

#aside{
    padding: 10px; float: right; width: 53%; text-align: justify;
    border-left: 0px #000000 solid;
}

#sectioner{
    padding: 10px; float: left; width: 38%; text-align: justify;
    border-right: 1px #000000 solid;
}

a:hover {
    cursor: pointer !important;
}

ul.navega li {
    display: inline !important;
    margin: 1px;
}


/*Editor de texto Quill*/

.text-editor {
    width: 1086px;
    margin: 0 auto;
}
.ql-editor {
    min-height: 150px;
    background-color: var(--color-background-editor-text) !important;
 }

 .ql-toolbar{
    background-color: var(--color-background-button-editor-text) !important;
}

.ql-picker-label:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-formats{
    color: var(--color-ql-snow-ql-toolbar-button-ql-active-ql-stroke) !important;
}

.ql-picker-item.ql-selected{
    color: var(--color-background-button-editor-text) !important;
}
.ql-snow.ql-toolbar button.ql-active .ql-stroke{
    color: var(--color-ql-snow-ql-toolbar-button-ql-active-ql-stroke) !important;
    stroke: var(--color-ql-snow-ql-toolbar-button-ql-active-ql-stroke) !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill{
    color: var(--color-ql-snow-ql-toolbar-button-ql-active-ql-stroke) !important;
    stroke: var(--color-ql-snow-ql-toolbar-button-ql-active-ql-stroke) !important;
    fill: var(--color-ql-snow-ql-toolbar-button-ql-active-ql-stroke) !important;
}

.ql-snow .ql-stroke {
    fill: none;
    stroke: var(--color-font-button-editor-text) !important;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: var(--color-font-button-editor-text) !important;
    stroke: none;
}

.ql-snow .ql-picker-options {
    background-color: var(--color-background-menu-editor-text) !important;
    color: var(--color-text-menu-editor-text) !important;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before{
    color: var(--color-font-button-editor-text) !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before{
    color: var(--color-font-button-editor-text) !important;
}

.ql-editor.ql-blank::before{
    color: var(--color-font-ql-editor-ql-blank-before) !important;
    background-color: var(--color-background-ql-editor-ql-blank-before) !important;
}

.ql-editor p{
   color: var(--color-text-editor-text) !important;
}

.ql-bold{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}

.ql-italic{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}

.ql-underline{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}

.ql-strike{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}

.ql-list{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}

.ql-indent{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-script{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-blockquote{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-direction{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-link{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-image{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-video{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-formula{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-code-block{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-clean{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-undo{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}
.ql-redo{
    background-color: var(--color-background-button-editor-text) !important;
    border: 1px var(--color-border-button-editor-text) solid !important;
}

.ql-bold:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-italic:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-underline:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-strike:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-list:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-indent:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-script:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-blockquote:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-direction:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-link:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-image:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-video:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-formula:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-code-block:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-clean:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-undo:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-redo:focus{
    background-color: var(--color-background-button-editor-text-focus) !important;
}


.ql-bold:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-italic:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-underline:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-strike:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-list:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-indent:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-script:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-blockquote:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-direction:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-link:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-image:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-video:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-formula:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-code-block:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-clean:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-undo:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

.ql-redo:hover{
    background-color: var(--color-background-button-editor-text-focus) !important;
}

/* ---------------------------------------------------------------
 * estilos para uso global (vía clases)
/* ---------------------------------------------------------------*/
.capitalize-firstLetter {
    text-transform: lowercase;
}
.capitalize-firstLetter::first-letter {
    text-transform: uppercase;
}
.placeholder-over-color, input.placeholder-over-color {
    color: var(--color-text-over-color);
}
.always-colored {
    color: var(--color-always-colored) !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.checkerboard-background {
    background-image: /* tint image */
                      linear-gradient(to right, rgba(192, 192, 192, 0.75), rgba(192, 192, 192, 0.75)),
                      /* checkered effect */
                      linear-gradient(to right, black 50%, white 50%),
                      linear-gradient(to bottom, black 50%, white 50%);
    background-blend-mode: normal, difference, normal;
    background-size: 2em 2em;
}
.themed-border {
    border-color: var(--borde-tarjetas);
    border-width: 1px;
    border-style: solid;
}
.cursor-not-allowed, .cursor-not-allowed * {
    cursor: not-allowed !important;
}

/* ---------------------------------------------------------------
 * tarjetas
/* ---------------------------------------------------------------*/
.tarjeta-ecampus {
    position: relative;
    width: 338px;
    height: 360px;
    border-color: var(--borde-tarjetas);
    border-width: 1px;
    border-style: solid;
    background-color: var(--background-tarjetas) !important;
}
.tarjeta-ecampus .title {
    color: var(--color-always-colored);
    line-height: 1.4 !important;
    font-size: 0.9rem !important;
}
.tarjeta-ecampus .subtitle {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: var(--primary-color);
    font-family: "Arial", "Helvetica", sans-serif;
}
.tarjeta-ecampus .MuiCardActions-root {
    margin-bottom: 5px;
    justify-content: flex-start;
}
.tarjeta-ecampus .MuiCardActions-root button {
    margin-left: 5px;
}
.tarjeta-ecampus-wrapper {
    justify-content: center;
}
.tarjeta-ecampus div.imagen-tarjeta {
    position: relative;
    /*overflow: clip;*/ /* clip no soportado en algunos exploradores */
    width: 100%;
    max-height: 210px; /* NOTA: usar 'max-height' si se quiere que el texto suba cuando la imagen tiene poco alto; o usar 'height' para alinear todas las descripciones sin importar el tamaño de las imágenes */
}
.tarjeta-ecampus div.wrapper-imagen-tarjeta {
    /* el wrapper permite simular el 'overflow: clip' de la imagen; necesario porque algunos exploradores aún no soportan overflow: clip; */
    width: 100%;
    max-height: 210px;
    overflow: hidden;
}
.tarjeta-ecampus div.imagen-tarjeta img {
    width: 100%;
}
.tarjeta-ecampus .description-tarjeta {
    padding-top: 0.5rem;
    padding-bottom: 0 !important;
}
.tarjeta-ecampus .description-tarjeta > * {
    margin-top: 0.2rem;
}
.tarjeta-ecampus .panel-inferior-tarjeta {
    margin-bottom: '5px';
    position: absolute;
    bottom: 0;
}
.tarjeta-ecampus .panel-inferior-tarjeta button {
    margin-bottom: '5px';
}

/*Tutor*/
.tarjeta-ecampus-tutor {
    height: 350px;
}
.tarjeta-ecampus-tutor .MuiCardActions-root {
    margin-bottom: 5px;
    justify-content: inherit !important;
}
.tarjeta-ecampus-tutor div.imagen-tarjeta {
    height: 210px;
}
.tarjeta-ecampus-tutor .panel-inferior-tarjeta {
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    gap: 5px;
}

/*carga de img*/
.tarjeta-ecampus-upload {
    width: 100%;
    border-color: var(--borde-tarjetas);
    border-width: 1px;
    border-style: solid;
    background-color: var(--background-tarjetas) !important;
}

.tarjeta-ecampus-upload div.imagen-tarjeta {
    position: relative;
    width: 100%;
    max-height: 210px; /* NOTA: usar 'max-height' si se quiere que el texto suba cuando la imagen tiene poco alto; o usar 'height' para alinear todas las descripciones sin importar el tamaño de las imágenes */

}
.tarjeta-ecampus-upload div.wrapper-imagen-tarjeta {
    /* el wrapper permite simular el 'overflow: clip' de la imagen; necesario porque algunos exploradores aún no soportan overflow: clip; */
    width: 100%;
    max-height: 210px;
    overflow: hidden;
}
.tarjeta-ecampus-upload div.imagen-tarjeta img {
    width: 100%;
}

.button_basurero {
    background-color: var(--color-background-form-butons-cancel) !important;
    color: rgb(71, 71, 71) !important;
    border: 1px solid var(--color-background-form-butons-cancel) !important;
}

@media screen and (max-width: 420px) {
    .tarjeta-grid-item {
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (max-width: 350px) {
    .tarjeta-ecampus {
        width: 290px !important;
    }
}

/* ---------------------------------------------------------------
 * breadcrumb
/* ---------------------------------------------------------------*/
nav.MuiBreadcrumbs-root {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}
nav.MuiBreadcrumbs-root > ol {
    height: 100%;
}
@media screen and (max-width: 420px) {
    nav.MuiBreadcrumbs-root {
        padding-left: 0;
    }
}

/* ---------------------------------------------------------------
 * menú usuario
/* ---------------------------------------------------------------*/
.MuiListItem-root.MuiButtonBase-root {
    /*display: flex !important;*/ /* comentado porque deshabilita la animación al mantener el click presionado sobre el item. TODO: lograr centrar para cualquier alto sin deshabilitar efecto. */
}
.MuiListItem-root.MuiButtonBase-root .MuiListItemIcon-root {
    align-items: center;
}
.MuiListItem-root.MuiButtonBase-root:hover:not(:focus) {
    background-color: var(--hover-effect) !important;
}

/* ---------------------------------------------------------------
 * layout páginas
/* ---------------------------------------------------------------*/
div.app-content .App {
    /* margen base - se usa padding en lugar de margin para no afectar el ancho máximo de la página */
    padding-left: 20px;
    padding-right: 20px;
}
div.app-content .bloque-contenido {
    margin-left: 40px;
    margin-right: 40px;
    padding-bottom: 40px;
}
div.app-content .bloque-contenido.fix-app-padding {
    margin-bottom: -150px; /* FIX: evitar este fix. */
}
div.app-content .module-content-button-bar {
    padding-right: 8px;
}
.content-narrow {
    max-width: 1070px;
}
.flex-content-centered {
    display: flex;
    justify-content: center;
}
.secondary-column {
    margin-top: -15px;
}
.fullpage-mensaje {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
@media screen and (max-width: 1920px) {
    /* lg */
    .only-on-xs {
        display: none !important;
    }
}
@media screen and (max-width: 1280px) {
    /* md */
}
@media screen and (max-width: 960px) {
    /* sm */
    .extras-block {
        margin-left: 20px;
    }
    .secondary-column .extras-block {
        margin-left: 5px;
    }
}
@media screen and (max-width: 600px) {
    /* xs */
    .secondary-column {
        display: none;
    }
    .bloque-contenido, h1.title_section, h2.title_section {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .section-c {
        margin-top: 30px;
    }
    .extras-block {
        margin-left: 5px;
    }
    .only-on-xs {
        display: block !important;
        margin-top: 1rem; /* fix: no corresponde aquí */
        margin-bottom: 1rem;
    }
    .hide-on-xs {
        display: none !important;
    }
    /* maximizar ancho disponible para contenido dentro de tabs */
    .MuiTabPanel-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media screen and (max-width: 420px) {
    /* xxs */
    div.app-content .App {
        padding-left: 10px;
        padding-right: 10px;
    }
    .section-c {
        margin-top: 15px;
    }
    .extras-block {
        margin-left: 0;
    }
    .only-on-xxs {
        display: block !important;
        margin-top: 1rem; /* fix: no corresponde aquí */
        margin-bottom: 1rem;
    }
    .hide-on-xxs {
        display: none !important;
    }
    /* maximizar ancho disponible para contenido dentro de tabs */
    .MuiTabPanel-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

/* ---------------------------------------------------------------
 * contenido / texto / margenes
/* ---------------------------------------------------------------*/
h1.title_section, h2.title_section {
    margin-top: 1rem;
    margin-left: 0.5rem;
    display: inline-block;
    width: 100%;
}
h1.title_section.sub-section, h2.title_section.sub-section {
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: 0;
    margin-top: 0.4rem;
}
.title_section.sub-title {
    font-size: 20px;
    margin-left: 0;
}
.section-c {
    margin-top: 40px;
}
.contenido-label-detalle {
    font-size: 15px;
    margin-bottom: 5px;
}
.content-description {
    font-size: 1rem;
    margin-bottom: 5px;
    color: var(--primary-color);
}
.content-description ul {
    list-style: disc;
}
.content-description ol {
    list-style: decimal;
}
.content-highlighted-label {
    margin-right: 0.2rem;
}
.stacked-block {
    width: 100%;
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
}
.stacked-block:nth-child(1) {
    margin-top: revert !important;
}
.stacked-block2 {
    width: 100%;
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important;
}
.stacked-block2:nth-child(1) {
    margin-top: revert !important;
}

/* ---------------------------------------------------------------
 * override user content style (ej: descripción de cursos)
/* ---------------------------------------------------------------*/
.content-description, .content-description * {
    color: var(--primary-color) !important;
}

/* ---------------------------------------------------------------
 * formularios
/* ---------------------------------------------------------------*/
@media screen and (max-width: 420px) {
    /* disminuir separación entre campos en pantallas pequeñas */
    form .title_section {
        margin-top: 10px !important;
    }
}

/* ---------------------------------------------------------------
 * fixes (TODO: corregir de fondo para evitar estos parches)
/* ---------------------------------------------------------------*/
/* color botón cerrar modal (icono x) */
.modal_title .MuiIconButton-label svg, .icon-over-color {
    fill: var(--color-text-over-color) !important;
}
/* botones panel superior (perfil y carrito) */
#botones-top-bar .MuiButton-root {
    min-height: 52px;
}
#botones-top-bar .button-perfil > button {
    padding-right: 0;
}
/* evitar que el carrusel corte las tarjetas */
.carrusel-contenedor-tarjeta {
    padding-bottom: 1rem;
    padding-top: 0.6rem;
}
.tituloInicial {
    padding-bottom: 20px;
    padding-top: 30px;
}
div.app-content .App .section-migas {
    padding-bottom: 20px;
    padding-top: 10px;
    border-bottom: 1px solid rgb(177, 177, 177) !important;
}
/* efecto :hover para acordeones */
.MuiAccordion-root {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
}
.MuiAccordion-root:hover {
    border-color: var(--primary-with-transparency);
}
.MuiAccordion-root:hover .MuiAccordionSummary-expandIcon svg {
    background-color: transparent !important;
}
/* separación acordeones */
.stack-acordeones {
    width: 100%;
}
.stack-acordeones > div {
    margin-top: 1rem !important;
}
/* hack to fix media cards at home (TODO: resolver de fondo) */
.fix-media-card-at-home-page {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    cursor: default !important;
    pointer-events: none;
    height: auto !important;
    min-height: 290px;
}
.fix-media-card-at-home-page > div.MuiCardMedia-root {
    height: 110px !important;
    width: 110px !important;
    max-height: 110px !important;
}
.fix-media-card-at-home-page > div.MuiCardContent-root {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
/* FIX: corregir posicionamiento de "app-content", "MenuBar" y ".App" para hacerlo consistente y no depender de paddings y margins ajustados manualmente */
.fix-position-of-first-page-item {
    margin-top: 23px;
}
@media screen and (max-width: 600px) {
    .fix-position-of-first-page-item {
        margin-top: 15px;
    }
}
/* evitar que el template en campos de fecha nativos se pueda ver parcialmente bajo el label */
input.input-date-label-fix:not(:focus):not(:valid), .input-date-label-fix input:not(:focus):not(:valid) {
    color: transparent;
}

/* ---------------------------------------------------------------
 * contenido acordeones unidades
/* ---------------------------------------------------------------*/
.container-detalle-unidad {
    padding: 0 !important;
}
.container-detalle-unidad > div {
    align-items: center;
    justify-content: space-between;
}
.container-detalle-unidad > div h6 {
    display: inline-block;
}
.container-detalle-unidad > div > *:nth-child(1) {
    display: flex;
    align-items: center;
    flex-grow: 2;
}
.container-detalle-unidad > div > *:nth-child(1) > *:nth-child(1) {
    align-self: start;
    margin: 0.3rem !important;
    margin-left: 0.1rem !important
}
.container-detalle-unidad > div > *:nth-child(1) > *:nth-child(2) {
    padding-left: 0.2rem;
    flex-grow: 5;
}
.container-detalle-unidad > div > *:nth-child(2) {
    flex-grow: 1;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
}
.container-detalle-unidad.sin-checkbox > div > *:nth-child(1) {
    padding-left: 1.2rem;
}
.interactive-accordion-item:hover > hr {
    border-top-style: solid;
    border-top-width: 1px;
}
.interactive-accordion-item:hover {
    box-shadow: -5px 0px 5px 0px rgb(0 0 0 / 10%);
}
html[data-theme="dark"] .interactive-accordion-item:hover > hr {
    border-top-color: white;
}
html[data-theme="dark"] .interactive-accordion-item:hover {
    box-shadow: -5px 0px 5px 0px rgb(255 255 255 / 34%);
}
/* fix dark background color */
html[data-theme="dark"] .stack-acordeones .MuiPaper-root, html[data-theme="dark"] .stack-acordeones .MuiPaper-root .MuiCheckbox-root {
    background-color: var(--color-background-form-butons-subscription-content) !important;
}
/* fix checkbox fill on light color */
.stack-acordeones .MuiCheckbox-root svg {
    fill: var(--primary-color);
}
.MuiAccordion-root .MuiCheckbox-root {
    background-color: transparent !important;
}

/* ---------------------------------------------------------------
 * carrito
/* ---------------------------------------------------------------*/
.TitleCart {
    padding: 0;
    display: flex;
    justify-content: flex-end;
}
#totales-contenedor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 30%;
    max-width: 400px
}
.totales-monto {
    display: flex;
    justify-content: space-between;
}
#limpiar-carro {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
}
.cart-item {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.cart-item:first-child {
    border-top: thin solid gray;
}
.cart-item p, .cart-item div {
    background-color: transparent !important;
}
.cart-item:hover {
    background-color: var(--hover-effect) !important;
}
.cart-item img {
    width: 298px;
    cursor: pointer;
}
.cart-item .cart-columna-detalles-item {
    display: flex;
}
.cart-item .cart-columna-detalles-item .title_section {
    margin-left: 1rem;
}
.cart-item .cart-columna-detalles-item p {
    margin-top: 5px;
}
.cart-item .cart-columna-detalles-item li {
    list-style: outside;
    margin-left: 1rem;
    color: var(--primary-color);
    line-height: 1.2rem;
    font-family: "Arial";
    font-size: 0.9rem;
    font-weight: 300;
}
.cart-item .cart-columna-precio-item {
    display: flex;
    gap: 10px;
}
@media screen and (max-width: 900px) {
    .cart-item img {
        width: 200px;
    }
}
@media screen and (max-width: 690px) {
    .cart-item img {
        display: none !important;
    }
}
@media screen and (max-width: 420px) {
    #totales-contenedor .totales-monto span, #totales-contenedor .totales-monto h1 {
        font-size: 1rem !important;
    }
    .cart-item .cart-columna-detalles-item p, .cart-item .cart-columna-detalles-item .title_section {
        font-size: 0.8rem !important;
        margin-left: 0;
    }
}

/* ---------------------------------------------------------------
 * mis compras
/* ---------------------------------------------------------------*/
.accordion-compra {
    margin-top: 1rem;
}
.accordion-compra label, .accordion-compra .MuiFormControlLabel-label {
    width: 100%;
}
.accordion-compra .MuiFormControlLabel-label > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.3rem;
}
.accordion-compra .MuiAccordionDetails-root > p:first-child {
    margin-bottom: 0.5rem;
}
.accordion-compra .MuiAccordionDetails-root li {
    list-style: outside;
    margin-left: 1rem;
    color: var(--primary-color);
    line-height: 1.2rem;
    font-family: "Arial";
    font-size: 0.9rem;
    font-weight: 300;
}

/* ---------------------------------------------------------------
 * country-selector
/* ---------------------------------------------------------------*/
#main-country-selector .MuiSelect-select {
    min-width: 8em;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
#main-country-selector .MuiSelect-icon {
    fill: var(--color-text-over-color);
}
#main-country-selector .MuiSelect-select .main-country-option {
    color: var(--color-text-over-color);
    background-color: transparent;
}
.main-country-option {
    display: flex;
    justify-content: space-between;
}
.main-country-option .main-country-option-code {
    opacity: 0.8;
    font-size: smaller;
    padding-right: 0.2em;
}
.MuiPopover-root[id^="menu-"] .placeholder-menu-option {
    /* ocultar del menú opciones marcadas con la clase "placeholder-menu-option" */
    display: none !important;
}
@media screen and (max-width: 420px) {
    #main-country-selector .MuiSelect-select {
        min-width: 7em;
    }
    #main-country-selector .main-country-option-code {
        display: none;
    }
    .MuiMenu-list .main-country-option {
        min-width: 8em;
    }
}
@media screen and (max-width: 400px) {
    #main-country-selector .MuiSelect-select {
        min-width: 5em;
        font-size: smaller;
    }
}

/* ---------------------------------------------------------------
 * top-bar responsive adjustments
/* ---------------------------------------------------------------*/
#botones-top-bar > div {
    flex-wrap: nowrap;
}
@media screen and (max-width: 420px) {
    #top-action-toolbar {
        padding-left: 0 !important;
    }
    #button-open-sidebar {
        margin-right: 4px;
    }
}
@media screen and (max-width: 318px) {
    #botones-top-bar > div {
        flex-wrap: wrap;
    }
}

/* ---------------------------------------------------------------
 * misc
/* ---------------------------------------------------------------*/
.ql-editor:focus-visible {
    min-height: 150px;
    /*color: var(--subtitulos) !important;*/
    outline: none !important;
    margin:             0px 0px 0px 0px;
    padding:            10px 0px 3px 12px;
}

/* ---------------------------------------------------------------
 * ribbon-bottom-right
 * based on: https://codepen.io/nxworld/pen/oLdoWb
/* ---------------------------------------------------------------*/
/* common */
.ribbon {
    --ribbon-color: #3498db;
}
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    background-color: transparent !important;
}
.ribbon::before, .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid var(--ribbon-color);
    filter: brightness(80%);
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: var(--ribbon-color);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}
/* bottom right*/
.ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
}
.ribbon-bottom-right::before, .ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon-bottom-right::before {
    bottom: -1px; /* deberá ser igual al tamaño del borde de la tarjeta a la que se aplique */
    left: 0;
}
.ribbon-bottom-right::after {
    top: 0;
    right: -1px; /* deberá ser igual al tamaño del borde de la tarjeta a la que se aplique */
}
.ribbon-bottom-right span {
    left: -25px;
    bottom: 36px;
    transform: rotate(315deg);
}

/* ---------------------------------------------------------------
 * ribbon1
 * based on: https://codepen.io/JohnOSTend/pen/ZXrwoM
/* ---------------------------------------------------------------*/
.ribbon1 {
    --ribbon-color: #F8463F;
}
.ribbon1 {
    position: absolute;
    top: -6.1px;
    right: 10px;
}
.ribbon1:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 53px solid transparent;
    border-right: 53px solid transparent;
    border-top: 10px solid var(--ribbon-color);
}
.ribbon1 span {
    position: relative;
    display: block;
    text-align: center;
    background: var(--ribbon-color);
    font-size: 14px;
    line-height: 1;
    padding: 12px 8px 10px;
    border-top-right-radius: 8px;
    width: 90px;
}
.ribbon1 span:before, .ribbon1 span:after {
    position: absolute;
    content: "";
}
.ribbon1 span:before {
    height: 6px;
    width: 6px;
    left: -6px;
    top: 0;
    background: var(--ribbon-color);
}
.ribbon1 span:after {
    height: 6px;
    width: 8px;
    left: -8px;
    top: 0;
    border-radius: 8px 8px 0 0;
    background: var(--ribbon-color);
    filter: brightness(80%);
}

/* ---------------------------------------------------------------
 * ribbon2
 * based on: https://codepen.io/JohnOSTend/pen/ZXrwoM
/* ---------------------------------------------------------------*/
.ribbon2 {
    --ribbon-color: #F47530;
}
.ribbon2 {
    width: 60px;
    padding: 10px 0;
    position: absolute;
    top: -6px;
    right: 25px;
    text-align: center;
    border-top-right-radius: 3px;
    background: var(--ribbon-color);
    z-index: 1;
}
.ribbon2.shadow {
    background: black;
    margin-right: 3px;
    margin-top: 3px;
    z-index: 0;
    opacity: 0.2;
}
.ribbon2:not(.shadow):before {
    height: 0;
    width: 0;
    left: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid var(--ribbon-color);
    border-left: 6px solid transparent;
    filter: brightness(80%);
}
.ribbon2:not(.shadow):before, .ribbon2:after {
    content: "";
    position: absolute;
}

.ribbon2:after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 30px solid var(--ribbon-color);
    border-right: 30px solid var(--ribbon-color);
    border-bottom: 30px solid transparent;
}
.ribbon2.shadow:after {
    border-left-color: black;
    border-right-color: black;
}

/* ---------------------------------------------------------------
 * ribbon2-left
 * based on: https://codepen.io/JohnOSTend/pen/ZXrwoM
/* ---------------------------------------------------------------*/
.ribbon2-left {
    --ribbon-color: #F47530;
}
.ribbon2-left {
    width: 60px;
    padding: 10px 0;
    position: absolute;
    top: -6px;
    left: 25px;
    text-align: center;
    border-top-left-radius: 3px;
    background: var(--ribbon-color);
    z-index: 1;
}
.ribbon2-left.shadow {
    background: black;
    margin-left: 3px;
    margin-top: 3px;
    z-index: 0;
    opacity: 0.2;
}
.ribbon2-left:not(.shadow):before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid var(--ribbon-color);
    border-right: 6px solid transparent;
    filter: brightness(80%);
}
.ribbon2-left:not(.shadow):before, .ribbon2-left:after {
    content: "";
    position: absolute;
}
.ribbon2-left:after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 30px solid var(--ribbon-color);
    border-right: 30px solid var(--ribbon-color);
    border-bottom: 30px solid transparent;
}
.ribbon2-left.shadow:after {
    border-left-color: black;
    border-right-color: black;
}

/* ---------------------------------------------------------------
 * ajustes tema oscuro
/* ---------------------------------------------------------------*/
a[href] {
    color: var(--color-a-link);
}
div.MuiInputBase-root {
    color: var(--primary-color);
}
div.MuiInputBase-root.Mui-disabled {
    color: var(--primary-with-transparency);
}

/* ---------------------------------------------------------------
 * Toasts / Notistack / Notificaciones
/* ---------------------------------------------------------------*/
.notistack-customized {
    cursor: default;
    width: 380px;
    max-width: 100%;
}
